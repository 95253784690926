import { API, graphqlOperation } from 'aws-amplify';
import { getOrderMinimalQuery, getOrderQuery } from 'src/backend/queries';
import { payOrder as payOrderLambda } from 'src/backend/graphql/mutations';

export async function getOrderMinimalApi(orderId: string) {
  try {
    const data = (await API.graphql(graphqlOperation(getOrderMinimalQuery, { id: orderId }))) as {
      data: any;
    };

    if (!data || !data.data || !data.data.getOrder) throw new Error('No order found');

    return data.data.getOrder;
  } catch (err) {
    return null;
  }
}

export async function getOrderApi(orderId: string) {
  try {
    const data = (await API.graphql(graphqlOperation(getOrderQuery, { id: orderId }))) as {
      data: any;
    };
    if (!data || !data.data || !data.data.getOrder) throw new Error('No order found');

    return data.data.getOrder;
  } catch (err) {
    return null;
  }
}

export async function payOrderApi(orderId: string, orderData: any) {
  try {
    const data = (await API.graphql({
      ...graphqlOperation(payOrderLambda, {
        orderID: orderId,
        ...orderData,
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as any;

    if (!data || !data.data || !data.data.payOrder) throw new Error('Error on pay order');

    return data.data.payOrder;
  } catch (err) {
    return null;
  }
}
