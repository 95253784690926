import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { getCorporateQuery } from 'src/backend/queries';
import * as api from 'src/backend/API';
import * as mutations from 'src/backend/graphql/mutations';
import { corporateInviteAssociateds as corporateInviteAssociatedsLambda } from 'src/backend/graphql/mutations';

type Corporates = {
  businessId: string;
  businessName: string;
  locationId: string;
  locationStatus: api.LocationStatus;
  locationName: string | null;
};

export async function getCorporatesApi(corporateIds: string[]) {
  const tempCorporates: Corporates[] = [];
  try {
    for (const corporate of corporateIds) {
      // eslint-disable-next-line no-await-in-loop
      const getCorporate = (await API.graphql(
        graphqlOperation(getCorporateQuery, {
          id: corporate,
        })
      )) as {
        data: any;
      };

      if (!getCorporate || !getCorporate.data) throw new Error('No corporate found');

      tempCorporates.push({
        businessName: getCorporate.data.getCorporate.business.name,
        businessId: getCorporate.data.getCorporate.business.id,
        locationId: getCorporate.data.getCorporate.id,
        locationStatus: getCorporate.data.getCorporate.status,
        locationName: getCorporate.data.getCorporate.name,
      });
    }

    return tempCorporates;
  } catch (err) {
    console.log('err', err);

    return tempCorporates;
  }
}

export async function getCorporateMinimalApi(corporateId: string) {
  try {
    const getCorporateMinimalQuery = /* GraphQL */ `
      query GetCorporate($id: ID!) {
        getCorporate(id: $id) {
          id
          name
          businessCorporateId
          business {
            name
            id
          }
        }
      }
    `;

    const data = (await API.graphql(
      graphqlOperation(getCorporateMinimalQuery, { id: corporateId })
    )) as {
      data: any;
    };
    if (!data || !data.data || !data.data.getCorporate) throw new Error('No corporate found');

    return data.data.getCorporate;
  } catch (err) {
    return null;
  }
}

export async function getCorporateApi(corporateId: string) {
  try {
    const data = (await API.graphql(graphqlOperation(getCorporateQuery, { id: corporateId }))) as {
      data: any;
    };

    if (!data || !data.data || !data.data.getCorporate) throw new Error('No corporate found');

    return data.data.getCorporate;
  } catch (err) {
    return null;
  }
}

export async function updateCorporateApi(corporateId: string, updateData: any) {
  try {
    const updateCorporateQuery = await API.graphql<GraphQLQuery<any>>({
      query: mutations.updateCorporate,
      variables: {
        input: {
          id: corporateId,
          ...updateData,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (updateCorporateQuery && updateCorporateQuery.data) {
      return true;
    }
    throw new Error('Update error');
  } catch (err) {
    if (err && err.data && err.data.updateCorporate && err.data.updateCorporate.id) return true;
    console.log('err', err);
    return false;
  }
}

export async function corporateInviteAssociatedApi(corporateId: string, userEmail: string) {
  try {
    const data = (await API.graphql({
      ...graphqlOperation(corporateInviteAssociatedsLambda, {
        corporateID: corporateId,
        userEmail,
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as any;

    console.log('data', data);

    if (!data || !data.data || !data.data.corporateInviteAssociated)
      throw new Error('Error on invite associated');

    return data.data.corporateInviteAssociated;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}
