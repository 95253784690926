export const paths = {
  login: '/login',
  verify: '/verify',
  register: {
    root: '/register',
    associated: (associatedId: string) => `/register/associated/${associatedId}`,
  },
  newPassword: '/new-password',
  forgotPassword: '/forgot-password',
};
