import { API, graphqlOperation } from 'aws-amplify';
import { getAssociatedQuery } from 'src/backend/queries';

export async function getAssociatedApi(associatedId: string) {
  try {
    const data = (await API.graphql(
      graphqlOperation(getAssociatedQuery, { id: associatedId })
    )) as {
      data: any;
    };
    if (!data || !data.data || !data.data.getAssociated) throw new Error('No associated found');

    return data.data.getAssociated;
  } catch (err) {
    return null;
  }
}
