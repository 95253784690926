import { useCallback, useEffect } from 'react';
// routes
import { useRouter, useSearchParams } from 'src/routes/hooks';
import { PATH_ADMIN, PATH_SIMPLE } from 'src/config-global';
//
import { useAuthContext } from '../hooks';

import RoleTypes from '../roles';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: Props) {
  const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const { authenticated, user } = useAuthContext();

  const check = useCallback(() => {
    if (authenticated && user) {
      console.log('user', user);
      if (returnTo) router.replace(returnTo);
      else if (
        user.role === RoleTypes.ROLE_0100 ||
        user.role === RoleTypes.ROLE_0190 ||
        user.role === RoleTypes.ROLE_0200
      )
        router.replace(PATH_ADMIN);
      else router.replace(PATH_SIMPLE);
      //
    }
  }, [authenticated, user, returnTo, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
