import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// AMPLIFY
const AmplifyLoginPage = lazy(() => import('src/pages/auth/amplify/login'));
const AmplifyRegisterPage = lazy(() => import('src/pages/auth/amplify/register'));
const AmplifyRegisterAssociatedPage = lazy(
  () => import('src/pages/auth/amplify/register-associated')
);
const AmplifyVerifyPage = lazy(() => import('src/pages/auth/amplify/verify'));
const AmplifyNewPasswordPage = lazy(() => import('src/pages/auth/amplify/new-password'));
const AmplifyForgotPasswordPage = lazy(() => import('src/pages/auth/amplify/forgot-password'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: '/',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthClassicLayout>
            <AmplifyLoginPage />
          </AuthClassicLayout>
        ),
      },
      {
        path: 'register',
        children: [
          {
            index: true,
            element: (
              <AuthClassicLayout title="Manage the job more effectively with Wanna pay">
                <AmplifyRegisterPage />
              </AuthClassicLayout>
            ),
          },
          {
            path: 'associated',
            children: [
              {
                element: (
                  <AuthClassicLayout title="Manage the job more effectively with Wanna pay">
                    <Navigate to="/register" replace />
                  </AuthClassicLayout>
                ),
                index: true,
              },
              {
                path: ':associatedId',
                element: (
                  <AuthClassicLayout title="Manage the job more effectively with Wanna pay">
                    <AmplifyRegisterAssociatedPage />
                  </AuthClassicLayout>
                ),
              },
            ],
          },
        ],
      },

      {
        element: (
          <CompactLayout>
            <Outlet />
          </CompactLayout>
        ),
        children: [
          { path: 'verify', element: <AmplifyVerifyPage /> },
          { path: 'new-password', element: <AmplifyNewPasswordPage /> },
          { path: 'forgot-password', element: <AmplifyForgotPasswordPage /> },
        ],
      },
    ],
  },
];
