import { useEffect } from 'react';
import { Hub } from 'aws-amplify';
// auth
import { useAuthContext } from 'src/auth/hooks';
// routes
import { paths } from 'src/routes/paths';
import { useSearchParams, useRouter } from 'src/routes/hooks';
// components
import { SplashScreen } from 'src/components/loading-screen';
//
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthConsumer({ children }: Props) {
  const { setCurrentUser } = useAuthContext();
  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const typeParam = searchParams.get('type');

  console.log('typeParam', typeParam);
  const returnToString = new URLSearchParams({
    returnTo: returnTo || '',
  }).toString();

  const router = useRouter();

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'autoSignIn') {
        const user = payload.data;

        console.log('user', user);
        setTimeout(() => {
          setCurrentUser(user);

          router.replace(returnTo ? `${paths.login}?${returnToString}` : paths.login);
        }, 500);
        // assign user
      } else if (event === 'autoSignIn_failure') {
        console.log('autoSignIn_failure');
        // redirect to sign in page
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Consumer>
      {(auth) => (auth.loading ? <SplashScreen /> : children)}
    </AuthContext.Consumer>
  );
}
