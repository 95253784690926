import { useEffect, useReducer, useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';
import { Auth } from '@aws-amplify/auth';
// api
import { ApiQueries } from 'src/backend';
//
import { IS_LOCALHOST, IS_TRISPI } from 'src/config-global';
import { AuthContext } from './auth-context';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';
import { getRoleAndIds } from '../../roles';
//
import { useSettingsAuthContext } from '../../settings/context';
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: Action) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const authSettings = useSettingsAuthContext();

  const initialize = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();

      setCurrentUser(currentUser);
    } catch (error) {
      console.error('error', error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string) => {
      const currentUser = await Auth.signIn(email, password);
      console.log('currentUser', currentUser);
      setCurrentUser(currentUser);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authSettings]
  );

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      type: 'simple' | 'business',
      link: string,
      businessName?: string
    ) => {
      if (authSettings.canReset) authSettings.onReset();

      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          'custom:type': type,
          'custom:link': link,
          ...(type === 'business' && { 'custom:businessName': businessName }),
        },
        // https://stackoverflow.com/questions/61751412/aws-amplify-auth-how-to-disable-amplifyconfirmsignup
        // https://docs.amplify.aws/javascript/prev/build-a-backend/auth/enable-sign-up/#auto-sign-in-after-sign-up
        autoSignIn: {
          enabled: true,
        },
      });
    },
    [authSettings]
  );

  // CONFIRM REGISTER
  const confirmRegister = useCallback(async (email: string, code: string) => {
    await Auth.confirmSignUp(email, code);
  }, []);

  // RESEND CODE REGISTER
  const resendCodeRegister = useCallback(async (email: string) => {
    await Auth.resendSignUp(email);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    if (authSettings.canReset) authSettings.onReset();

    await Auth.signOut();
    dispatch({
      type: Types.LOGOUT,
    });
  }, [authSettings]);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    await Auth.forgotPassword(email);
  }, []);

  // NEW PASSWORD
  const newPassword = useCallback(async (email: string, code: string, password: string) => {
    await Auth.forgotPasswordSubmit(email, code, password);
  }, []);

  // SELECT LOCATION
  const selectLocation = useCallback(
    async (location: string) => {
      const getLocation = await ApiQueries.getLocationMinimalApi(location)
        .promise()
        .then((data: any) => data)
        .catch(() => null);
      if (getLocation)
        authSettings.onUpdate('selectedLocation', {
          locationId: getLocation.id,
          businessId: getLocation.business.id,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // set current user
  const setCurrentUser = useCallback(
    async (currentUser: any) => {
      let payload = {
        user: null,
      };
      try {
        if (currentUser) {
          const currentUserGroups =
            currentUser.signInUserSession.accessToken.payload['cognito:groups'];

          const { role, locationIds, locations, roles } = await getRoleAndIds(currentUserGroups);
          const user = await ApiQueries.getUserApi(currentUser.attributes.sub)
            .promise()
            .then((data: any) => data)
            .catch(() => null);

          if (typeof locationIds[0] === 'string') {
            selectLocation(locationIds[0]);

            // Set the cookie
            const cookieOptions = {
              domain: IS_LOCALHOST ? 'localhost' : IS_TRISPI ? '.trispi.com' : '.wannapay.it',
              path: '/',
              expires: 365, // Cookie expiry in days
            };

            Cookies.set(
              'auth-settings',
              JSON.stringify({
                selectedLocation: {
                  businessId: locations[0].businessId,
                  locationStatus: locations[0].locationStatus,
                  locationId: locations[0].locationId,
                  locationName: locations[0].locationName || locations[0].businessName,
                  role,
                },
                allLocations: locations.map((location: any) => ({
                  businessId: location.businessId,
                  locationStatus: location.locationStatus,
                  locationId: location.locationId,
                  locationName: location.locationName || location.businessName,
                  role,
                })),
              }),
              cookieOptions
            );
          }
          payload = {
            user: {
              ...user,
              displayName: user
                ? user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : user.businessName
                    ? user.businessName
                    : user.email
                : null,
              role,
              locationIds,
              locations,
              roles,
            },
          };
          dispatch({
            type: Types.INITIAL,
            payload,
          });
        } else {
          dispatch({
            type: Types.INITIAL,
            payload,
          });

          Cookies.remove('auth-settings', {
            domain:
              process.env.NODE_ENV === 'development'
                ? 'localhost'
                : IS_TRISPI
                  ? '.trispi.com'
                  : '.wannapay.it',
            path: '/',
          });
        }
      } catch (error) {
        console.error('error', error);
        dispatch({
          type: Types.INITIAL,
          payload,
        });

        Cookies.remove('auth-settings', {
          domain:
            process.env.NODE_ENV === 'development'
              ? 'localhost'
              : IS_TRISPI
                ? '.trispi.com'
                : '.wannapay.it',
          path: '/',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'amplify',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
      register,
      newPassword,
      forgotPassword,
      confirmRegister,
      resendCodeRegister,
      selectLocation,
      setCurrentUser,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
      register,
      newPassword,
      forgotPassword,
      confirmRegister,
      resendCodeRegister,
      selectLocation,
      setCurrentUser,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
