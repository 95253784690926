import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Amplify } from 'aws-amplify';
import { Predictions, AmazonAIPredictionsProvider } from '@aws-amplify/predictions';
import awsExports from './aws-exports';
import { IS_LOCALHOST, IS_TRISPI } from './config-global';
//
import App from './App';

// ----------------------------------------------------------------------
Amplify.configure({
  ...awsExports,
  cookieStorage: {
    domain: IS_LOCALHOST ? document.location.hostname : IS_TRISPI ? 'trispi.com' : 'wannapay.it',
    secure: !IS_LOCALHOST,
    path: '/',
    expires: 365,
  },
});
Amplify.register(Predictions);
Predictions.addPluggable(new AmazonAIPredictionsProvider());

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
