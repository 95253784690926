export const getMenuQuery = /* GraphQL */ `
  query GetMenu($id: ID!) {
    getMenu(id: $id) {
      id
      businessId
      menuLocationId
      disabled
      name
      nameTranslations {
        language
        body
        __typename
      }
      description
      descriptionTranslations {
        language
        body
        __typename
      }
      wineList
      onlyAssociated
      categorySortOrder
      createdAt
      updatedAt
      categories {
        items {
          id
          name
          disabled
          description
          wineList
          subProductSortOrder
          createdAt
          updatedAt
          products {
            items {
              product {
                id
                plu
                name
                description
                price
                disabled
                productType
                isVariant
                isWine
                wineInfo {
                  grape {
                    grapePercentage
                    grapeName
                  }
                  year
                  typology
                  taste
                  servingTemperature
                  region
                  producer
                  perfume
                  perfectFor
                  country
                  color
                  caption
                  alcoholPercentage
                  productionMethod
                }
                dense
                snoozeStart
                snoozeEnd
                image
              }
            }
          }
        }
      }
      location {
        id
        images
        logo
        name
        businessLocationsId
        menuSortOrder
        selfOrdering
        externalLinks {
          icon
          label
          link
        }
        template
        color
        showCurrency
        showWineList
      }
    }
  }
`;

export const getLocationQuery = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      businessLocationsId
      name
      status
      timeZone
      selfOrdering
      translationsInProgress
      menuSortOrder
      externalLinks {
        icon
        label
        link
      }
      posts {
        items {
          id
          name
          image
          userName
          userId
          createdAt
        }
      }
      template
      color
      showCurrency
      showWineList
      logo
      images
      description
      bio
      coverPrice
      address {
        street
        province
        postalCode
        houseNumber
        country
        city
      }
      social {
        link
        label
        icon
      }
      gallery {
        image
        name
        nameTranslations {
          language
          body
        }
      }
      openingDay {
        monday {
          slots {
            start
            end
          }
        }
        tuesday {
          slots {
            start
            end
          }
        }
        wednesday {
          slots {
            start
            end
          }
        }
        thursday {
          slots {
            start
            end
          }
        }
        friday {
          slots {
            start
            end
          }
        }
        saturday {
          slots {
            start
            end
          }
        }
        sunday {
          slots {
            start
            end
          }
        }
      }
      phoneNumber
      email
      languageDefault
      languagesEnabled
      business {
        name
        id
      }
      stripeInfo {
        stripeSubscriptionCancelAtPeriodEnd
        stripeSubscriptionTrialEnd
        stripeSubscriptionEnd
        stripeSubscriptionStatus
        stripeSubscription
        stripeCostumer
        stripeAccountRequirements
        stripeAccount
      }
      menu {
        items {
          availabilities {
            dayOfWeek
            endTime
            startTime
          }
          id
        }
      }
    }
  }
`;

export const getLocationPostQuery = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      posts {
        items {
          id
          name
          image
          userName
          userId
          createdAt
        }
      }
    }
  }
`;

export const menuByLocationQuery = /* GraphQL */ `
  query GetMenusByLocation($menuLocationId: ID!, $filter: ModelMenuFilterInput) {
    menuByLocation(menuLocationId: $menuLocationId, filter: $filter) {
      items {
        id
        name
        nameTranslations {
          language
          body
          __typename
        }
        description
        descriptionTranslations {
          language
          body
          __typename
        }
        categorySortOrder
        disabled
        onlyAssociated
        createdAt
        updatedAt
        categories {
          items {
            id
            name
            nameTranslations {
              language
              body
              __typename
            }
            description
            descriptionTranslations {
              language
              body
              __typename
            }
            disabled
            subProductSortOrder
            products {
              items {
                id
                product {
                  id
                  plu
                  image
                  price
                  snoozeStart
                  snoozeEnd
                  isWine
                  wineInfo {
                    grape {
                      grapePercentage
                      grapeName
                    }
                    year
                    typology
                    productionMethod
                    taste
                    servingTemperature
                    region
                    producer
                    perfume
                    perfectFor
                    country
                    color
                    caption
                    alcoholPercentage
                  }
                  dense
                  name
                  nameTranslations {
                    language
                    body
                    __typename
                  }
                  description
                  descriptionTranslations {
                    language
                    body
                    __typename
                  }
                  subProductSortOrder
                  subProducts {
                    items {
                      id
                      productID
                      subProductsID
                      subProducts {
                        id
                        product {
                          name
                          id
                          price
                          subProducts {
                            items {
                              id
                              productID
                              subProductsID
                              subProducts {
                                id
                                product {
                                  name
                                  id
                                  price
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  disabled
                  productTags
                  categories {
                    items {
                      category {
                        id
                        name
                      }
                    }
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCategoryQuery = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      businessId
      menuCategoriesId
      locationId
      disabled
      menu {
        id
        businessId
        menuLocationId
        disabled
        name
        type
        nestedModifiers
        image
        currency
        description
        wineList
        onlyAssociated
        categorySortOrder
        createdAt
        updatedAt
        __typename
      }
      name
      nameTranslations {
        language
        body
        __typename
      }
      description
      descriptionTranslations {
        language
        body
        __typename
      }
      image
      posCategoryType
      posCategoryId
      posLocationId
      availabilities {
        dayOfWeek
        startTime
        endTime
        __typename
      }
      level
      sortedChannelProductIds
      subProductSortOrder
      subCategories {
        nextToken
        __typename
      }
      products {
        items {
          product {
            id
            plu
            name
            description
            price
            disabled
            productType
            isVariant
            isWine
            wineInfo {
              grape {
                grapePercentage
                grapeName
              }
              year
              typology
              taste
              servingTemperature
              region
              producer
              perfume
              perfectFor
              country
              color
              caption
              alcoholPercentage
              productionMethod
            }
            dense
            snoozeStart
            snoozeEnd
            image
            categories {
              items {
                categoryID
              }
            }
            createdAt
            updatedAt
          }
        }
      }
      createdAt
      updatedAt
      categorySubCategoriesId
      __typename
    }
  }
`;

export const getCategoryLocationQuery = /* GraphQL */ `
  query GetCategoryLocationQuery($id: ID!) {
    getCategory(id: $id) {
      id
      locationId
    }
  }
`;

export const getProductWithCategoriesQuery = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      businessId
      categories {
        items {
          categoryID
          id
          productID
        }
        nextToken
        __typename
      }
      locationId
      name
      nameTranslations {
        language
        body
        __typename
      }
      description
      descriptionTranslations {
        language
        body
        __typename
      }
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      multiMax
      multiply
      plu
      disabled
      dense
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isVariant
      isWine
      wineInfo {
        grape {
          grapePercentage
          grapeName
        }
        year
        typology
        taste
        servingTemperature
        region
        producer
        perfume
        perfectFor
        country
        color
        caption
        alcoholPercentage
        productionMethod
      }
      subProductSortOrder
      subProducts {
        items {
          id
          productID
          subProductsID
          subProducts {
            id
            product {
              name
              id
              price
              subProducts {
                items {
                  id
                  productID
                  subProductsID
                  subProducts {
                    id
                    product {
                      name
                      id
                      price
                    }
                  }
                }
              }
            }
          }
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($input: DeleteProductInput!, $condition: ModelProductConditionInput) {
    deleteProduct(input: $input, condition: $condition) {
      id
      businessId
      categories {
        items {
          id
        }
        nextToken
        __typename
      }
      locationId
      name
      nameTranslations {
        language
        body
        __typename
      }
      description
      descriptionTranslations {
        language
        body
        __typename
      }
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      multiMax
      multiply
      plu
      disabled
      dense
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isVariant
      isWine
      wineInfo {
        grape {
          grapePercentage
          grapeName
        }
        year
        typology
        taste
        servingTemperature
        region
        producer
        perfume
        perfectFor
        country
        color
        caption
        alcoholPercentage
        productionMethod
      }
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteCategoriesProductsQuery = /* GraphQL */ `
  mutation DeleteCategoriesProducts(
    $input: DeleteCategoriesProductsInput!
    $condition: ModelCategoriesProductsConditionInput
  ) {
    deleteCategoriesProducts(input: $input, condition: $condition) {
      id
    }
  }
`;

export const listProducts = /* GraphQL */ `
  query ListProducts(
    $id: ID
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProducts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        businessId
        locationId
        name
        description
        image
        capacityUsages
        deliveryTax
        eatInTax
        takeawayTax
        max
        min
        multiMax
        multiply
        plu
        disabled
        categories {
          items {
            id
          }
        }
        dense
        snoozeStart
        snoozeEnd
        price
        posCategoryIds
        posProductCategoryId
        posProductId
        productTags
        productType
        isVariant
        isWine
        wineInfo {
          grape {
            grapePercentage
            grapeName
          }
          year
          typology
          taste
          servingTemperature
          region
          producer
          perfume
          perfectFor
          country
          color
          caption
          alcoholPercentage
          productionMethod
        }
        subProductSortOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const createCategoriesProducts = /* GraphQL */ `
  mutation CreateCategoriesProducts($categoryID: ID!, $productID: ID!, $locationId: ID!) {
    createCategoriesProducts(
      input: { productID: $productID, categoryID: $categoryID, locationId: $locationId }
    ) {
      id
      category {
        id
        subProductSortOrder
      }
      product {
        id
        plu
      }
    }
  }
`;

export const deleteCategoriesProducts = /* GraphQL */ `
  mutation DeleteCategoriesProducts($id: ID!) {
    deleteCategoriesProducts(input: { id: $id }) {
      id
      category {
        id
        subProductSortOrder
      }
      product {
        id
        plu
      }
    }
  }
`;

export const getCorporateQuery = /* GraphQL */ `
  query GetCorporateQuery($id: ID!) {
    getCorporate(id: $id) {
      id
      name
      business {
        id
        name
      }
      associatedLocations {
        items {
          id
          createdAt
          location {
            id
            name
          }
        }
      }
      associates {
        items {
          id
          userEmail
          status
          user {
            id
            firstName
            lastName
            createdAt
          }
        }
      }
    }
  }
`;

export const getOrderMinimalQuery = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      location {
        id
        name
      }
      business {
        id
        name
      }
    }
  }
`;

export const getOrderQuery = /* GraphQL */ `
  query GetOrderQuery($id: ID!) {
    getOrder(id: $id) {
      id
      orderItemsSortOrder
      subTotal
      total
      businessId
      locationId
      paid
      status
      ristoQuickStatus
      table {
        id
        name
      }
      orderItems {
        items {
          id
          name
          quantity
          total
          orderItemSubItemsId
          payment
          subItems {
            items {
              id
              name
              quantity
              total
              orderItemSubItemsId
              payment
              subItems {
                items {
                  id
                  name
                  quantity
                  total
                  orderItemSubItemsId
                  payment
                  subItems {
                    items {
                      id
                      name
                      quantity
                      total
                      orderItemSubItemsId
                      payment
                      subItems {
                        items {
                          id
                          name
                          quantity
                          total
                          orderItemSubItemsId
                          payment
                          subItems {
                            items {
                              id
                              name
                              quantity
                              total
                              orderItemSubItemsId
                              payment
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getUserQuery = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      type
      coin
      totalCoin
      stripeCustomerId
      association {
        items {
          id
          status
          corporate {
            name
            id
          }
        }
      }
      firstName
      businessName
      taxCode
      sdi
      lastName
      fiscalCode
      address {
        street
        houseNumber
        postalCode
        city
        province
        country
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const getAssociatedQuery = /* GraphQL */ `
  query getAssociated($id: ID!) {
    getAssociated(id: $id) {
      id
      userId
      userEmail
      user {
        id
      }
    }
  }
`;
